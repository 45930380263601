
<template>
  <v-list-item
    class="pr-1"
    selectable
  >
    <v-list-item-action
      class="my-1 mr-4"
      :size="10"
    >
      <arkscore-gauge
        v-if="gene.resistance"
        :key="'arkscore-gauge-' + gene.result.detected ? gene.resistance.score : null"
        :value="gene.result.detected ? gene.resistance.score : null"
        :size="33"
      />
      <v-icon
        v-else
        color="error"
      >
        fas fa-exclamation-triangle
      </v-icon>
    </v-list-item-action>
    <v-list-item-content
      v-if="gene.resistance"
      :class="{ 'grey--text': !gene.result.detected }"
    >
      {{ gene.resistance.name.en }}
    </v-list-item-content>
    <v-list-item-content
      v-else
      color="red"
    >
      <h4>Unclassified</h4>
    </v-list-item-content>
    <v-list-item-action>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-chip
            :class="{ 'grey--text': !gene.result.detected }"
            small
            outlined
            v-on="on"
          >
            {{ gene.gene | truncate(10) }}
          </v-chip>
        </template>
        {{ gene.gene }}
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action>
      <v-tooltip
        right
        :color="relevant.length ? 'success darken-1' : null"
        open-delay="500"
      >
        <template #activator="{ on, attrs }">
          <v-avatar
            size="15"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              small
              :color="relevant.length ? 'success' : null"
              v-text="gene.result.detected ? (relevant.length ? 'fas fa-check' : 'fal fa-check') : 'fal fa-times grey--text'"
            />
          </v-avatar>
        </template>
        <template v-if="relevant.length">
          Relevant in:
          <li
            v-for="(organism, index) in relevant.flatMap(x => x.name)"
            :key="index"
            class="mb-0"
            v-text="organism"
          />
        </template>
        <template v-else>
          Not relevant in any<br>detected organisms
        </template>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
export default {
  components: {
    ArkscoreGauge: () => import('@/components/arkscore/ArkscoreGauge.vue'),
  },
  props: {
    gene: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    organisms: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    relevant () {
      return this.gene.resistance ? this.organisms.filter(x => x.relevant_resistances.map(x => x.name.en).includes(this.gene.resistance.name.en)) : []
    },
  },
}
</script>
